/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React, { useMemo } from 'react';
import { Formik, Field as FormikField } from 'formik';
import cx from 'classnames';
import { find, get, map, reduce } from 'lodash';
import * as yup from 'yup';

import Select from '@broadleaf/admin-components/dist/common/components/Select';
import { FieldDecorations } from '@broadleaf/admin-components/dist/form/helpers/FieldDecorations';
import SimpleModal from '@broadleaf/admin-components/dist/common/components/SimpleModal';
import Spinner from '@broadleaf/admin-components/dist/common/elements/Spinner';
import useEventCallback from '@broadleaf/admin-components/dist/common/hooks/useEventCallback';
import useFormatMessage from '@broadleaf/admin-components/dist/common/hooks/useFormatMessage';
import FormikError from '@broadleaf/admin-components/dist/form/components/FormikError';
import {
  clearFormikErrors,
  setFormikErrors
} from '@broadleaf/admin-components/dist/form/utils/RequestErrorHelpers';

import messages from './UpdateFulfillmentSettingsModal.messages';
import {
  findComponent,
  hasComponent
} from '@broadleaf/admin-components/dist/metadata/utils/MetadataUtils/MetadataUtils';
import classNames from 'classnames';
import {
  IMetadata,
  IMetadataFieldComponent
} from '@broadleaf/admin-components/dist/types/metadata';
import {
  getAttributeAmount,
  getMaxFulfillmentFee,
  getMaxRevMedFee,
  getRevMedSalesUsers,
  IOrderFulfillmentSettings
} from '../../utils/RmFulfillmentUtils';
import { IOrderFulfillment } from '@broadleaf/admin-components/dist/types/oms';
import { useFormatNumber } from '@broadleaf/admin-components/dist/common';
import { getMerchandiseTotal } from '@broadleaf/admin-components/dist/oms/utils/FulfillmentUtils';

export const UpdateFulfillmentSettingsModal: React.FC<UpdateFulfillmentSettingsModalProps> = ({
  metadata,
  fulfillment,
  fulfillmentSettings,
  onClose,
  onSubmit,
  isSubmitting = false,
  title
}) => {
  const initialValues = useInitialValues({ fulfillment, fulfillmentSettings });
  const validationSchema = useValidationSchema({
    fulfillment,
    fulfillmentSettings,
    metadata
  });
  const handleSubmit = useEventCallback(
    async (values, formik) => {
      formik.setSubmitting(true);
      try {
        await onSubmit(values);
        formik.setSubmitting(false);
        clearFormikErrors(formik);
        onClose();
      } catch (error) {
        setFormikErrors(error, formik);
        formik.setSubmitting(false);
      } finally {
        formik.setSubmitting(false);
      }
    },
    [onSubmit]
  );
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {formik => (
        <SimpleModal
          /*// @ts-ignore */
          closeOnClickOutside={false}
          footer={
            <ModalFooter
              formik={formik}
              isSubmitting={isSubmitting}
              submitLabel={'Update'}
              //TODO determine if this is needed
              submitNote={''}
            />
          }
          isOpen
          onClose={onClose}
          size="lg"
          title={title}
        >
          <Fields
            formik={formik}
            fulfillment={fulfillment}
            metadata={metadata}
          />
        </SimpleModal>
      )}
    </Formik>
  );
};

export interface UpdateFulfillmentSettingsModalProps {
  metadata: IMetadata;
  fulfillment: IOrderFulfillment;
  fulfillmentSettings: IOrderFulfillmentSettings;
  onClose: () => void;
  onSubmit: Function;
  isSubmitting: boolean;
  title: string;
}

const Fields = props => {
  const { formik, fulfillment, metadata } = props;
  const formatNumber = useFormatNumber();
  const maxRevMedFee = useMemo(() => {
    return getMaxRevMedFee(fulfillment).amount;
  }, [fulfillment]);
  const merchandiseTotal = useMemo(() => {
    return getMerchandiseTotal(fulfillment).amount;
  }, [fulfillment]);
  const revMedFee = useMemo(() => {
    let revMedFeeCalc = getAttributeAmount(fulfillment, 'revMedFee');
    if (
      formik.values.revMedFeeAmountOverride ||
      formik.values.revMedFeePercentOverride
    ) {
      const revMedFeeAmountOverride =
        formik.values.revMedFeeAmountOverride || 0;
      const revMedFeePercentOverride =
        formik.values.revMedFeePercentOverride || 0.15;
      revMedFeeCalc = {
        amount: Math.min(
          revMedFeeAmountOverride + merchandiseTotal * revMedFeePercentOverride,
          maxRevMedFee
        ),
        currency: fulfillment.currency
      };
    }
    return revMedFeeCalc;
  }, [formik.values, fulfillment, maxRevMedFee]);

  const maxFulfillmentFee = useMemo(() => {
    return getMaxFulfillmentFee(fulfillment, revMedFee).amount;
  }, [fulfillment, revMedFee]);

  const minFulfillmentFee = useMemo(() => {
    return 0 - revMedFee.amount - (fulfillment.attributes.insuranceCost || 0);
  }, [fulfillment, revMedFee]);
  return (
    <div>
      <div className="tw-mb-3 tw-flex tw-flex-col">
        <div className="tw-flex-1 tw-text-lg">RevMed Service Fee Settings</div>
        <div className="tw-flex tw-flex-1 tw-flex-row">
          <RevMedFeeAmountOverrideInput formik={formik} max={maxRevMedFee} />
          <RevMedFeePercentOverrideInput formik={formik} max={1} />
        </div>

        <div className="tw-flex tw-flex-1 tw-flex-row">
          <div className="tw-flex-1"></div>
          <div className="tw-flex-1"></div>
          <div className="tw-flex tw-flex-1 tw-flex-col">
            <div className="tw-flex-1">Current RevMed Fee:</div>
            <div className="tw-flex-1">Max RevMed Fee:</div>
          </div>
          <div className="tw-flex tw-flex-1 tw-flex-col">
            <span className="tw-flex-1 tw-text-right">
              {formatNumber(revMedFee.amount, {
                style: 'currency',
                currency: revMedFee.currency
              })}
            </span>
            <span className="tw-flex-1 tw-text-right">
              {formatNumber(maxRevMedFee, {
                style: 'currency',
                currency: revMedFee.currency
              })}
            </span>
          </div>
        </div>

        <div className="tw-mt-12 tw-mb-2 tw-flex-1 tw-text-lg">
          RevMed Fulfillment Settings
        </div>
        <div className="tw-flex tw-flex-1 tw-flex-row">
          <FulfillmentOptionOverrideField
            className="tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0"
            formik={formik}
            metadata={findComponent(metadata, {
              name: 'fulfillmentOptionOverride'
            })}
          />
          <FulfillmentFulfilledByField
            className="tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0"
            formik={formik}
            metadata={findComponent(metadata, {
              name: 'fulfilledBy'
            })}
          />
        </div>

        <div className="tw-flex tw-flex-row">
          <InspectionPeriodInput formik={formik} />
          <FulfillmentFeeInput
            formik={formik}
            max={maxFulfillmentFee}
            min={minFulfillmentFee}
            name={`revMedFulfillmentFee`}
          />
        </div>

        {(fulfillment.attributes['paymentType'] === 'INVOICE_CREDIT' ||
          fulfillment.attributes['paymentType'] === 'STORE_CREDIT') && (
          <div className="tw-flex tw-flex-row">
            <PONumberInput formik={formik} name={`poNumber`} />
            <div className="tw-flex-1"></div>
          </div>
        )}
      </div>
      {hasComponent(metadata, { name: 'reason' }) && (
        <StatusReason
          {...props}
          metadata={findComponent(metadata, { name: 'reason' })}
        />
      )}
    </div>
  );
};

const FulfillmentFeeInput = ({ className = '', formik, max, min, name }) => {
  const formatMessage = useFormatMessage();
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Fulfillment Fee'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            max={max}
            min={min}
            name={name}
            showMax={true}
            showMin={true}
            showError={showError}
            step=".01"
            type="number"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const PONumberInput = ({ className = '', formik, name }) => {
  const formatMessage = useFormatMessage();
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'PO Number'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            name={name}
            showError={showError}
            type="text"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const InspectionPeriodInput = ({ className = '', formik }) => {
  const formatMessage = useFormatMessage();
  const name = 'inspectionPeriodDays';
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'Inspection Period Days',
    helpText:
      'The number of business days allowed for inspection before auto-accepting the order'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            max="100"
            min="0"
            name={name}
            showError={showError}
            step="1"
            type="number"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const RevMedFeeAmountOverrideInput = ({ className = '', formik, max }) => {
  const formatMessage = useFormatMessage();
  const name = 'revMedFeeAmountOverride';
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'RevMed Flat Service Fee',
    helpText:
      'Enter a flat fee amount to override the default RevMed service fee'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            max={max}
            min="0"
            name={name}
            showError={showError}
            step=".01"
            type="number"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const RevMedFeePercentOverrideInput = ({ className = '', formik, max }) => {
  const formatMessage = useFormatMessage();
  const name = 'revMedFeePercentOverride';
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name);
  const showError = !!touched && !!error;
  const metadata = {
    label: 'RevMed Percent Service Fee',
    helpText:
      'Enter a percent (between 0 and 1) fee amount to override the default RevMed service fee'
  } as IMetadataFieldComponent;
  return (
    <div className="tw-flex-1">
      <div
        className={cx(
          className,
          'tw-flex tw-items-center tw-justify-end tw-whitespace-nowrap',
          'tw-flex-initial tw-self-end tw-px-3 lg:tw-mt-3 lg:tw-self-start lg:tw-px-0'
        )}
      >
        <FieldDecorations formik={formik} metadata={metadata}>
          <FormikField
            className="tw-mr-1 tw-flex tw-flex-1 tw-rounded tw-border tw-border-gray-300 tw-px-2 tw-py-1"
            max={max}
            min="0"
            name={name}
            showError={showError}
            step=".01"
            type="number"
            component={Input}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const Input = ({
  field,
  min,
  max,
  showMin = false,
  showMax = false,
  form = { errors: [], touched: [] },
  showError = true,
  ...props
}) => {
  const formatNumber = useFormatNumber();
  return (
    <div className="tw-flex tw-w-full tw-flex-1 tw-flex-row">
      <div className="tw-flex-1">
        <input
          {...field}
          {...props}
          className={cx(
            'tw-mr-1 tw-w-full tw-rounded tw-border tw-px-2 tw-py-1',
            {
              'tw-border-gray-300': !showError,
              'tw-border-red-600': showError
            }
          )}
        />
      </div>
      {showMin && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Min:{' '}
          {formatNumber(min, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
      {showMax && (
        <div className="tw-flex-1 tw-py-1 tw-text-right">
          Max:{' '}
          {formatNumber(max, {
            style: 'currency',
            currency: 'USD'
          })}
        </div>
      )}
    </div>
  );
};

const FulfillmentOptionOverrideField = props => {
  const { formik, metadata = { label: 'Fulfillment Option Override' } } = props;
  const name = 'fulfillmentOverrideOption';
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name, false);
  const optionsValue = get(formik.values, name, '');
  const options = [
    { value: 'NONE', label: 'None' },
    { value: 'REVMED_DROPOFF', label: 'RevMed Dropoff' }
  ];
  const selectedOption = useMemo(() => {
    return find(options, { value: optionsValue });
  }, [optionsValue, options]);
  return (
    <div className="tw-flex-1">
      <FieldDecorations fullWidth {...props} metadata={metadata}>
        <Select
          className={cx({
            'is-invalid': touched && !!error
          })}
          isClearable={false}
          isMulti={false}
          isDisabled={formik.isSubmitting}
          onChange={operator => {
            formik.setFieldValue(name, operator?.value);
          }}
          getOptionValue={option => option.value}
          onBlur={formik.handleBlur}
          options={options}
          name={name}
          placeholder={metadata?.placeholder || 'Override Option'}
          value={selectedOption}
        />
      </FieldDecorations>
    </div>
  );
};

const FulfillmentFulfilledByField = props => {
  const { formik, metadata = { label: 'Fulfillment By' } } = props;
  const name = 'fulfilledByEmail';
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name, false);
  const optionsValue = get(formik.values, name, '');
  const options = getRevMedSalesUsers();
  const selectedOption = useMemo(() => {
    return find(options, { value: optionsValue });
  }, [optionsValue, options]);
  return (
    <div className="tw-flex-1">
      <FieldDecorations fullWidth {...props} metadata={metadata}>
        <Select
          className={cx({
            'is-invalid': touched && !!error
          })}
          isClearable={false}
          isMulti={false}
          isDisabled={formik.isSubmitting}
          onChange={operator => {
            formik.setFieldValue(name, operator?.value);
          }}
          getOptionValue={option => option.value}
          onBlur={formik.handleBlur}
          options={options}
          name={name}
          placeholder={metadata?.placeholder || 'Choose RevMed User'}
          value={selectedOption}
        />
      </FieldDecorations>
    </div>
  );
};

const StatusReason = props => {
  const { formik, metadata } = props;
  const name = metadata.name;
  const error = get(formik.errors, name);
  const touched = get(formik.touched, name, false);
  const optionsValue = get(formik.values, name, '');
  return (
    <div className="tw-flex tw-flex-row">
      <div className="tw-flex-1"></div>
      <div className="tw-flex-1">
        <FieldDecorations fullWidth {...props} metadata={metadata}>
          <Select
            className={cx({
              'is-invalid': touched && !!error
            })}
            isClearable={false}
            isMulti={false}
            isDisabled={formik.isSubmitting}
            onChange={operator => {
              formik.setFieldValue(name, operator);
            }}
            onBlur={formik.handleBlur}
            options={metadata.options}
            name={name}
            placeholder={metadata.placeholder || 'Choose a reason'}
            value={optionsValue}
          />
        </FieldDecorations>
      </div>
    </div>
  );
};

const ModalFooter = ({ formik, isSubmitting, submitLabel, submitNote }) => {
  return (
    <>
      {formik.isSubmitting ? (
        <Spinner className="tw-mr-2" />
      ) : (
        <FormikError className="tw-mr-2" formik={formik} />
      )}

      <span className="tw-text-sm tw-text-gray-700">{submitNote}</span>
      <button
        disabled={isSubmitting}
        className={classNames(
          'tw-text-md focus:tw-shadow-outline tw-w-full tw-rounded tw-border tw-bg-primary-500 tw-px-4 tw-py-4 tw-font-semibold tw-text-primary-100 tw-shadow hover:tw-bg-primary-600 focus:tw-outline-none md:tw-w-auto md:tw-py-2',
          {
            'tw-cursor-not-allowed': isSubmitting
          }
        )}
        onClick={() => formik.submitForm()}
        style={{ opacity: isSubmitting ? '0.65' : '1' }}
        type="submit"
      >
        {' '}
        {submitLabel}{' '}
        {isSubmitting && (
          <Spinner
            size="md"
            className="tw-ml-2"
            innerClassName="tw-border-gray-100"
          />
        )}
      </button>
    </>
  );
};

function useInitialValues({ fulfillment, fulfillmentSettings }) {
  return useMemo(() => {
    return {
      fulfillmentId: fulfillment.id,
      ...fulfillmentSettings
    };
  }, [fulfillment, fulfillmentSettings]);
}

function useValidationSchema({ fulfillment, fulfillmentSettings, metadata }) {
  const formatMessage = useFormatMessage();
  const revMedFeeAmountMinimumMessage = formatMessage(
    messages.revMedFeeAmountMinimum
  );
  const revMedFulfillmentFeeMinimumMessage = formatMessage(
    messages.revMedFulfillmentFeeMinimum
  );
  const maxFullfillmentFee = fulfillmentSettings.maxRevMedFulfillmentFee;
  const revMedFulfillmentFeeMaximum = formatMessage(
    messages.revMedFulfillmentFeeMaximum,
    {
      max: maxFullfillmentFee
    }
  );
  const fulfillByRequired = formatMessage(messages.fulfilledByRequired);
  return useMemo(() => {
    const validation = yup.object().shape({
      fulfillmentId: yup.string(),
      //TODO add proper validation and messages
      fulfilledByEmail: yup.string(),
      fulfillmentOverrideOption: yup.string(),
      revMedFeeAmountOverride: yup
        .number()
        .nullable()
        .transform(emptyStringToNull)
        .min(0, revMedFeeAmountMinimumMessage),
      revMedFeePercentOverride: yup
        .number()
        .nullable()
        .transform(emptyStringToNull),
      revMedFulfillmentFee: yup
        .number()
        .nullable()
        .transform(emptyStringToNull)
        .test(
          'min',
          'Fulfillment fee must be greater then RevMed Fee + Ship Insurance negated',
          function test(revMedFulfillmentFee) {
            const revMedFee =
              (this.parent.revMedFeeAmountOverride || 0) +
              (this.parent.revMedFeePercentOverride || 0) *
                fulfillment.merchandiseTotal.amount;
            const insuranceCost = fulfillment.attributes.insuranceCost;
            const minFulfillmentFee = 0 - revMedFee - insuranceCost;
            return revMedFulfillmentFee >= minFulfillmentFee;
          }
        )
        .max(maxFullfillmentFee, revMedFulfillmentFeeMaximum)
    });

    return validation;
  }, [
    revMedFulfillmentFeeMaximum,
    revMedFulfillmentFeeMinimumMessage,
    maxFullfillmentFee
  ]);
}

function emptyStringToNull(value, originalValue) {
  if (typeof originalValue === 'string' && originalValue === '') {
    return null;
  }
  return value;
}

export default UpdateFulfillmentSettingsModal;
