/*
Copyright (C) 2009 - 2019 Broadleaf Commerce.

Licensed under the Broadleaf End User License Agreement (EULA),
Version 1.1 (the “Commercial License” located at
http://license.broadleafcommerce.org/commercial_license-1.1.txt).

Alternatively, the Commercial License may be replaced with a mutually
agreed upon license (the “Custom License”) between you and
Broadleaf Commerce. You may not use this file except in compliance
with the applicable license.
*/
import React from 'react';

import DashboardTile from './DashboardTile';
import {
  ViewAccountsIcon,
  ViewCategoriesIcon,
  ViewCustomersIcon,
  ViewListingIcon,
  ViewOrdersIcon
} from '../icons';
import { Environment } from '@broadleaf/admin-components/dist/common';

const tiles = [
  {
    link: '/customers',
    label: 'Customers',
    icon: ViewCustomersIcon,
    isApplication: true
  },
  {
    link: '/customer-accounts',
    label: 'Accounts',
    icon: ViewAccountsIcon,
    isApplication: true
  },
  {
    link: '/fulfillments',
    label: 'Account Sales',
    icon: ViewOrdersIcon,
    isApplication: false
  },
  {
    link: '/products',
    label: 'Products',
    icon: ViewListingIcon,
    isApplication: true
  },
  {
    link: '/categories',
    label: 'Categories',
    icon: ViewCategoriesIcon,
    isApplication: true
  }
];

function getSiteUrl() {
  return Environment.get('site.url', '');
}

export const Dashboard = () => {
  return (
    <div className="card tw-px-4">
      <div className="card-body">
        <h5 className="card-title tw-mb-8 tw-text-center tw-text-3xl">
          RevMed Admin Portal
        </h5>
        <ul className="tw-flex tw-list-none tw-flex-wrap tw-justify-center tw-space-y-3 tw-px-32 sm:tw-space-x-3 sm:tw-space-y-0 sm:tw-px-0">
          {tiles.map(({ link, label, icon, isApplication }) => (
            <DashboardTile
              key={label}
              link={link}
              label={label}
              Icon={icon}
              isApplication={isApplication}
            />
          ))}
        </ul>
        <p className="card-title tw-mt-8 tw-text-center tw-text-xl tw-text-link-700">
          <a href={getSiteUrl()}>Go to RevMed Main Site</a>
        </p>
      </div>
    </div>
  );
};

export default Dashboard;
